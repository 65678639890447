




















import Vue from "vue";

export default Vue.extend({
  data() {
    return {
      item: null,
      currentTab: "",
      tabs: {
        partners: {
          label: "Dòng tiền công nợ đối tác",
        },
        shops: {
          label: "Công nợ tổng quan các nền tảng",
        },
      },
    };
  },
  async created() {},
});
